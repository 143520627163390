<!--
* @description:
* @fileName shopManage.vue
* @author hvv
* @date 2022/01/13 15:21:21
!-->
<template>
  <div>
    <vab-query-form style="padding-top: 20px">
      <!-- <vab-query-form-left-panel :span="12">
        <el-button type="info">导出表格</el-button>
        <el-button type="primary" @click="addRegisterCode">
          添加注册码
        </el-button>
      </vab-query-form-left-panel> -->
      <vab-query-form-right-panel :span="24">
        <el-form :inline="true" :model="queryForm" @submit.prevent>
          <el-form-item label="注册码">
            <el-input v-model="queryForm.name" placeholder="请输入注册码" />
          </el-form-item>
          <el-form-item label="手机号">
            <el-input v-model="queryForm.phone" placeholder="请输入手机号" />
          </el-form-item>
          <el-form-item label="投放状态">
            <el-select
              v-model="queryForm.sendStatus"
              class="m-2"
              placeholder="请选择投放状态"
            >
              <el-option
                v-for="item in sendStatusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="使用状态">
            <el-select
              v-model="queryForm.useStatus"
              class="m-2"
              placeholder="请选择使用状态"
            >
              <el-option
                v-for="item in useStatusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button :icon="Search" type="primary" @click="queryData">
              查询
            </el-button>
            <el-button :icon="Search" type="danger" @click="clearSearch">
              置空
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column
        label="注册码"
        prop="invitationCode"
        show-overflow-tooltip
      />
      <el-table-column
        label="投放手机号"
        prop="sendPhone"
        show-overflow-tooltip
      />
      <el-table-column label="投放时间" prop="sendTime" show-overflow-tooltip />
      <el-table-column label="投放状态" prop="sendStatus">
        <template #default="{ row }">
          <el-tag v-if="row.sendStatus == 'SUCCESS'" type="success">
            投放成功
          </el-tag>
          <el-tag v-if="row.sendStatus == 'FAIL'" type="danger">
            投放失败
          </el-tag>
          <el-tag v-if="row.sendStatus == 'SNEDING'" type="warning">
            投放中
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="使用者姓名" prop="name">
        <template #default="{ row }">
          <span v-if="row.name != null">{{ row.name }}</span>
          <span v-else>/</span>
        </template>
      </el-table-column>
      <el-table-column label="使用者手机号" prop="phone">
        <template #default="{ row }">
          <span v-if="row.phone != null">{{ row.phone }}</span>
          <span v-else>/</span>
        </template>
      </el-table-column>

      <el-table-column label="使用时间" prop="useTime">
        <template #default="{ row }">
          <span v-if="row.useTime != null">{{ row.useTime }}</span>
          <span v-else>/</span>
        </template>
      </el-table-column>

      <el-table-column label="使用状态" prop="status">
        <template #default="{ row }">
          <el-tag v-if="row.status == 'TO_BE_USE'" type="success">
            待使用
          </el-tag>
          <el-tag v-if="row.status == 'INVALIDATE'" type="danger">作废</el-tag>
          <el-tag v-if="row.status == 'USED'" type="warning">已使用</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" show-overflow-tooltip width="200">
        <template #default="{ row }">
          <template v-if="row.status != null && row.status != 'INVALIDATE'">
            <el-button type="primary" @click="handleEdit(row)">
              再次邀请
            </el-button>
            <el-button type="danger" @click="handleDelete(row)">作废</el-button>
          </template>

          <template v-else>/</template>
        </template>
      </el-table-column>
      <template #empty>
        <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNumber"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <invite ref="inviteRef" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import {
    defineComponent,
    defineAsyncComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
    onActivated,
  } from 'vue'

  import { Delete, Plus, Search } from '@element-plus/icons'
  import { smsInviteUserList, zuofeiRegisterCode } from '@/api/hostApply'
  export default defineComponent({
    name: 'TicketList',
    components: {
      Invite: defineAsyncComponent(() => import('./components/inviteSms.vue')),
    },
    setup() {
      const { proxy } = getCurrentInstance()

      const state = reactive({
        queryForm: {
          code: '',
          pageNo: 1,
          pageSize: 20,
          phone: '',
          sendStatus: 'ALL',
          useStatus: 'ALL',
        },
        total: 0,
        shopList: [],
        listLoading: false,
        list: [],
        layout: 'prev, pager, next',
        inviteRef: null,
        sendStatusOptions: [
          {
            label: '全部',
            value: 'ALL',
          },
          {
            label: '投放成功',
            value: 'SUCCESS',
          },
          {
            label: '投放中',
            value: 'SNEDING',
          },
          {
            label: '投放失败',
            value: 'FAIL',
          },
        ],
        useStatusOptions: [
          {
            label: '全部',
            value: 'ALL',
          },
          {
            label: '已使用',
            value: 'USED',
          },
          {
            label: '待使用',
            value: 'TO_BE_USE',
          },
          {
            label: '作废',
            value: 'INVALIDATE',
          },
        ],
      })

      const handleEdit = (row) => {
        state.inviteRef.showEdit(row)
      }
      const handleDelete = (row) => {
        if (row.invitationCode) {
          proxy.$baseConfirm('你确定要作废当前邀请码吗？', null, async () => {
            await zuofeiRegisterCode({ invitationCode: row.invitationCode })
            proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
            await fetchData()
          })
        }
      }

      const queryData = () => {
        fetchData()
      }
      const clearSearch = () => {
        state.queryForm = {
          code: '',
          pageNum: 1,
          pageSize: 20,
          phone: '',
          sendStatus: 'ALL',
          useStatus: 'ALL',
        }
        queryData()
      }

      const fetchData = async () => {
        state.listLoading = true
        const { data } = await smsInviteUserList(state.queryForm)
        state.list = data.list
        state.total = data.total
        state.listLoading = false
      }

      const handleSizeChange = (val) => {
        state.queryForm.pageSize = val
        queryData()
      }
      const handleCurrentChange = (val) => {
        state.queryForm.pageNo = val
        queryData()
      }

      const addRegisterCode = () => {
        console.log('add')
        state.addRef.showEdit()
      }

      onMounted(() => {
        queryData()
      })
      return {
        ...toRefs(state),
        handleEdit,
        queryData,
        fetchData,
        handleSizeChange,
        handleCurrentChange,
        handleDelete,
        addRegisterCode,
        clearSearch,
        Delete,
        Search,
        Plus,
      }
    },
  })
</script>
<style lang="scss" scoped></style>
